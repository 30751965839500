// Here you can add other styles

$theme-color : #f6ac00;

.theme-bg {
    background-color: $theme-color !important;
}

.theme-text {
    color: $theme-color !important;
}

.theme-border-color {
    border-color: $theme-color !important;
}

.error {
	font-size: 14px;
	color: red;
	font-weight: 600;
}



.them-button {
	background: $theme-color !important;
	border-color: $theme-color !important;
	padding: 9px 34px !important;
font-size: 20px !important;
border-radius: 5px !important;
box-shadow: none !important;
}

.dashboardlogo.sidebar-brand img.sidebar-brand-full {
	width: auto;
	height: 75px;
	margin: 10px 0;
}

.pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 50px;
}

.page-link.active, .active > .page-link {
	background-color: $theme-color;
	border-color: $theme-color;
    box-shadow: none !important;
}

.page-link.active:hover:focus:focus-visible:focus-within:visited:target, .active > .page-link:hover:focus:focus-visible:focus-within:visited:target {
	color: #fff;
}

.page-link, .page-link:hover, .page-link:focus {
	color: $theme-color;
    box-shadow: none !important;
}

.active .page-link:hover:focus:focus-visible:focus-within:visited:target{
	color: white !important;
}


.btn-outline-primary {
	--cui-btn-color:  #f6ac00;
	--cui-btn-border-color:#f6ac00;
	--cui-btn-hover-bg: #f6ac00;
	--cui-btn-hover-border-color: #f6ac00;
	--cui-btn-active-bg: #f6ac00;
	--cui-btn-active-border-color: #f6ac00;
	--cui-btn-disabled-color:#f6ac00;
}


.btn-primary {
	--cui-btn-color: #fff;
	--cui-btn-bg: #f6ac00;
	--cui-btn-border-color: #f6ac00;
	--cui-btn-hover-bg: #f6ac00;
	--cui-btn-hover-border-color: #f6ac00;
	--cui-btn-active-bg: #f6ac00;
	--cui-btn-active-border-color: #f6ac00;
	--cui-btn-disabled-bg: #f6ac00;
	--cui-btn-disabled-border-color: #f6ac00;
	padding: 11px 28px;
	box-shadow: none !important;
}

.cursor-pointer {
	cursor: pointer;
}

.page-title {
	margin: 0;
	font-weight: 600;
}


.appoinmentdetailsmodal .modal-dialog {
	max-width: 920px;
}

.page-link.active, .active > .page-link:hover, .page-link.active, .active > .page-link:focus {
	color: white;
}
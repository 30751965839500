/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;
$theme-colors: (
  "primary": red,
  "danger": #ff4136
);

// Import styles with default layout.
@import "~@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "~@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

.custom-table{

th{
  background: #303c54;
  padding-top: 12px;
  color: #fff;
  padding-bottom: 12px;
}
td, th {
  padding: 10px 15px;
  vertical-align: middle;
  border-left: 1px solid #ccc;
}
 
td:first-child, th:first-child {
  border-left: 0;
}
}